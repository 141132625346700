export default {
  colors: {
    active1: "#00d1b2",
    logo: "#f84949",
    secondary: "#505aff",
    secondaryLight1: "#9672c0",
    primary: "#00044b",
    primary2: "#fe4f67",

    main1: "#ff455e",
    dark1: "#000000",
    grey1: "#505050",
    grey2: "#979797",
    light1: "#ffffff",
    gradient1: `linear-gradient(270deg, rgba(255, 135, 160, 0) 0%, rgba(254, 105, 130, 0.69) 15%, rgba(252, 80, 103, 0.91) 100%)`,
    // Component Specific
    buttonDefault: "#505aff",
    overlap: "#00044b",
    tricolor1: "#1fb3e3",
    tricolor2: "#00a1ff",
    tricolor3: "#021f71",
    sTrifold1: "#2baad2",
    sTrifold2: "#2f35a3",
    sTrifold3: "#00044b",
    formCircle: "#57d089",
    formLine1: "#5dcba6",
    formLine2: "#16d2c8",
    label: "#00a1ff",
    pmGraphic: "#edf3ff",
  },
  breakpoints: {
    nav: 1250,
    mobile: "only screen and (max-width: 680px)",
    tabletSm: "only screen and (max-width: 1000px)",
    tablet: "only screen and (max-width: 1250px)",
    desktop: "only screen and (min-width: 1250px)",
  },
  sizes: {
    xxSmall: ".25rem",
    xSmall: ".5rem",
    small: "1rem",
    smMd: "1.25rem",
    medium: "1.875rem",
    large: "3rem",
    xLarge: "2.75rem",
    xxLarge: "3.75rem",
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.2s ease",
  },
  shadows: {
    shadow1: "0px 5px 20px rgba(30, 30, 31, 0.05)",
  },
}
